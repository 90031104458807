import './style.scss';
import {cookie} from "./js/cookies-eu-banner";
cookie();

document.addEventListener("DOMContentLoaded", function() {
    const accordionButton = document.querySelector('.accordion-button');
    const openSymbol = document.querySelector('.open');
    const closeSymbol = document.querySelector('.close');
    const panel = document.querySelector('.panel');


    openSymbol.style.display = 'inline';
    closeSymbol.style.display = 'none';

    accordionButton.addEventListener('click', function(e) {
        if (e.target.classList.contains('accordion-button-text') || e.target.classList.contains('open')) {
            if(panel.classList.contains('open')) {
                panel.classList.remove('open');
                openSymbol.style.display = 'inline';
                closeSymbol.style.display = 'none';
            } else {
                panel.classList.add('open');
                openSymbol.style.display = 'none';
                closeSymbol.style.display = 'inline';
            }
        }
        if (e.target.classList.contains('close')) {
            panel.classList.remove('open');
            openSymbol.style.display = 'inline';
            closeSymbol.style.display = 'none';
        }
    });
});

